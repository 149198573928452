* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}


body {
    overflow: hidden;
    height: 100dvh;
    background-image: url('/public/viola-ili-anjutiny-glazki.jpg');
}


.App {
    width: 100%;
    height: 100%;
}
.title {
    font-size: 140px;
    color: #ffff;
    text-align: center;
    margin-top: 40px;

}

.title span {
    color: green;
}
.swiper {
    width: 200px;
    height: 200px;
}
.slide {
    width: 200px;
    height: 200px;
}
.slide img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.slider1 {
    position: absolute;
    top: 0;
    left: 0;
}
.slider2 {
    position: absolute;
    top: 0;
    right: 0;
}
.slider3 {
    position: absolute;
    right: 0;
    bottom: 0;
}
.slider4 {
    position: absolute;
    left: 0;
    bottom: 0;
}
.content {
    margin-top: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
    font-weight: 600;
}
.subtitle {
    font-size: 40px;
    color: aquamarine;
}
.subtitle2 {
    font-size: 45px;
    color: darkred;
    font-style: italic;
}
.subtitle3 {
    font-size: 45px;
    color: lime;
    font-style: italic;
}
.subtitle4 {
    font-size: 35px;
    color: gold;
    font-style: oblique;
}
.low-quality {
    filter: blur(1px)  brightness(70%) contrast(90%) saturate(90%);
}
.container {
    position: absolute;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    top: 0;
}

.kitty {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100px; /* Размеры вашего котика */
    height: auto;
    animation: fly 5s infinite alternate;
}

@keyframes fly {
    0% {
        transform: translate(-50%, -50%) translateX(0);
    }
    100% {
        transform: translate(-50%, -50%) translateX(100vw);
    }
}


@media (width <= 540px) {
    body {
        overflow: auto;
    }
    .slider1 {
        bottom: 0;
        height: 200px;
        z-index: -1;

    }
    .slider2 {
        /*display: none;*/
        z-index: -1;

    }
    .slider3 {
        /*display: none;*/
        z-index: -1;

    }
    .slider4 {
        /*display: none;*/
        z-index: -1;
    }
    .title {
        font-size: 35px;
    }
    .content {
        padding: 0 60px;
    }
    .subtitle {
        font-size: 26px;
    }
    .subtitle2 {
        font-size: 26px;
    }
    .subtitle3 {
        font-size: 26px;
    }
    .subtitle4 {
        font-size: 26px;
    }
}
